import * as React from 'react';
import {ContentModal} from '../../ui/modals/ContentModal';

interface ContactInfoSuccessModalProps {
    onClose: () => void;
    show: boolean;
}

export const ContactInfoSuccessModal = ({onClose, show}: ContactInfoSuccessModalProps) => (
    <ContentModal
        onClose={onClose}
        show={show}
        title="Confirmation"
    >
        <div>
            Your request for contact information updates have been sent for approval. Your Dedicated Account Advisor may follow up to
            confirm this information. Please expect 1-2 business days to appear on your account.
        </div>
    </ContentModal>
);
