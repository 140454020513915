import * as React from 'react';

interface LinkButtonProps {
    className?: string;
    customStyle?: React.CSSProperties;
    disabled?: boolean;
    e2e?: string;
    id?: string;
    onClick?: (() => void) | ((e: any) => void);
    text: string | JSX.Element;
}

export const LinkButton = ({className, disabled, customStyle, e2e, id, onClick, text}: LinkButtonProps) => (
    <button
        className={`p-0 btn btn-link${className ? ` ${className}` : ''}`}
        data-e2e={e2e ? e2e : undefined}
        disabled={disabled}
        id={id}
        onClick={onClick}
        style={customStyle ? customStyle : {}}
        type="button"
    >
        {text}
    </button>
);
