import * as yup from 'yup';

import {Order} from '../../orders/order.class';

export const contactInfoModalValidation = yup.object({
    billToAddr1: yup
        .string()
        .label(`Billing Street Address`)
        .matches(/.{3,}/, {excludeEmptyString: true, message: `Billing Street Address must be at least 3 characters`})
        .max(25),
    billToAddr2: yup.string().label(`Billing Address 2`).max(25),
    billToCity: yup
        .string()
        .label(`Billing City`)
        .matches(/.{3,}/, {excludeEmptyString: true, message: `Billing City must be at least 3 characters`})
        .max(18),
    billToEmail: yup
        .string()
        .label(`Billing E-Mail`)
        .matches(/.{3,}/, {excludeEmptyString: true, message: `Billing E-Mail must be at least 3 characters`})
        .email()
        .max(65),
    billToName: yup
        .string()
        .label(`Billing Name`)
        .matches(/.{3,}/, {excludeEmptyString: true, message: `Billing Name must be at least 3 characters`})
        .max(25),
    billToPhone: yup
        .string()
        .label(`Billing Phone #`)
        .matches(/.{10,}/, {excludeEmptyString: true, message: `Billing Phone # must be at least 10 characters`})
        .max(14),
    billToState: yup.string().label(`Billing State`),
    billToZip: yup
        .string()
        .label(`Billing Postal Code`)
        .matches(/.{5,}/, {excludeEmptyString: true, message: `Billing Postal Code must be at least 5 characters`})
        .max(10),
    contactEmail: yup
        .string()
        .label(`E-Mail`)
        .matches(/.{3,}/, {excludeEmptyString: true, message: `E-Mail must be at least 3 characters`})
        .email()
        .max(65),
    contactName: yup
        .string()
        .label(`Name`)
        .matches(/.{3,}/, {excludeEmptyString: true, message: `Name must be at least 3 characters`})
        .max(25),
    contactPhone: yup
        .string()
        .label(`Phone #`)
        .matches(/.{10,}/, {excludeEmptyString: true, message: `Phone # must be at least 10 characters`})
        .max(14),
    shipToAddr1: yup
        .string()
        .label(`Shipping Address`)
        .matches(/.{3,}/, {excludeEmptyString: true, message: `Shipping Address must be at least 3 characters`})
        .max(25)
        .test(`noPoBox`, `Cannot ship to a PO Box`, (value) => {
            return !Order.isPoBox(value);
        }),
    shipToAddr2: yup
        .string()
        .label(`Shipping Address 2`)
        .max(25)
        .test(`noPoBox`, `Cannot ship to a PO Box`, (value) => {
            return !Order.isPoBox(value);
        }),
    shipToCity: yup
        .string()
        .label(`Shipping City`)
        .matches(/.{3,}/, {excludeEmptyString: true, message: `Shipping City must be at least 3 characters`})
        .max(18),
    shipToEmail: yup
        .string()
        .label(`Shipping E-Mail`)
        .matches(/.{3,}/, {excludeEmptyString: true, message: `Shipping E-Mail must be at least 3 characters`})
        .email()
        .max(65),
    shipToName: yup
        .string()
        .label(`Name`)
        .matches(/.{3,}/, {excludeEmptyString: true, message: `Name must be at least 3 characters`})
        .max(25),
    shipToPhone: yup
        .string()
        .label(`Shipping Phone #`)
        .matches(/.{10,}/, {excludeEmptyString: true, message: `Shipping Phone # must be at least 10 characters`})
        .max(14),
    shipToState: yup.string().label(`Shipping State`),
    shipToZip: yup
        .string()
        .label(`Shipping Postal Code`)
        .matches(/.{5,}/, {excludeEmptyString: true, message: `Shipping Postal Code must be at least 5 characters`})
        .max(10),
});
